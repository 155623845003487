import 'tailwindcss/tailwind.css'
import '../styles/index.css';
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <ToastContainer/>
    </>
  )
}